<template>
  <ul class="clebex-item-section company-preference-calendar">
    <li
      class="clebex-item-section-item full-right-underline"
      v-for="(key, value) in preferenceValueLabels.WEEKEND_DAYS"
      :key="key"
      @click="setData(value)"
    >
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <label class="clebex-section-input-label">{{
            displayLabelName("preferences", "preference-category", key)
          }}</label>
        </div>
        <span
          class="follow-up-icons"
          v-if="
            preferenceUpdateData.values.some(
              prefValue => prefValue.value === value
            )
          "
        >
          <span class="follow-up-icon-item">
            <icon icon="#cx-app1-checkmark" width="12" height="12"></icon>
          </span>
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { preferenceValueLabels } from "@/services/preference-options";
import { mapActions, mapState } from "vuex";

export default {
  name: "WeekendDays",
  data() {
    return {
      preferenceValueLabels: preferenceValueLabels
    };
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("companyPreferences", ["setPreferenceUpdateData"]),
    setData(value) {
      let newPreferenceValues = [...this.preferenceUpdateData.values];
      if (newPreferenceValues.length) {
        if (!newPreferenceValues.some(prefValue => prefValue.value === value)) {
          if (newPreferenceValues.length < 6) {
            newPreferenceValues.push({
              value: value
            });
          }
        } else {
          if (newPreferenceValues.length > 1) {
            newPreferenceValues.splice(
              newPreferenceValues.findIndex(function(item) {
                return item.value == value;
              }),
              1
            );
          }
        }
      } else {
        newPreferenceValues.push({
          value: value
        });
      }
      const newPreferenceUpdateData = {
        values: newPreferenceValues
      };
      this.setPreferenceUpdateData(newPreferenceUpdateData);
    }
  }
};
</script>
